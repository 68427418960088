<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div class="container row ml-2 mt-3 mb-2">
      <div class="col-2">
        <h3>Ordenes</h3>
      </div>
      <div class="col-3">
        <input
          type="text"
          v-model="orderId"
          placeholder="Buscar por ORDER ID"
          class="form-control"
        />
      </div>
    </div>
    <div class="container-fluid" style="height: 700px; overflow: scroll;">
      <table class="table table-custom">
        <thead>
          <tr>
            <th class="table-title" scope="col">ID</th>
            <th class="table-title" scope="col">cliente</th>
            <th class="table-title" scope="col">Email</th>
            <th class="table-title" scope="col">Teléfono</th>
            <th class="table-title" scope="col">Fecha</th>
            <th class="table-title" scope="col">Estado</th>
            <th class="table-title" scope="col">Delivery</th>
            <th class="table-title" scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, index) in totalOrders" :key="index">
            <th class="table-content" scope="row">
              <button
                @click="viewInfo(order)"
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                class="see-btn"
              >
                {{ order.id }}
              </button>
            </th>
            <td class="table-content">
              {{ order.get("user").get("fullName") }}
            </td>
            <td class="table-content">
              {{ order.get("user").get("username") }}
            </td>
            <td
              v-if="order.get('user').get('phoneNumber') != undefined"
              class="table-content"
            >
              {{ order.get("user").get("phoneNumber") }}
            </td>
            <td
              v-if="order.get('user').get('phoneNumber') === undefined"
              class="table-content"
            >
              No hay Teléfono
            </td>
            <td class="table-content">{{ order.get("date") }}</td>
            <td class="table-content">{{ status(order.get("status")) }}</td>

            <td class="table-content">
              <p
                v-if="order.get('currentDriver') === undefined"
                class="table-content"
              >
                No hay driver
              </p>
              <p
                v-if="order.get('currentDriver') != undefined"
                class="table-content"
              >
                {{ order.get("currentDriver").get("fullName") }}
              </p>
            </td>
            <td class="table-content">
              <p
                v-if="
                  order.get('status') === 'C' || order.get('status') === 'F'
                "
              >
                {{ status(order.get("status")) }}
              </p>
              <button
                v-if="order.get('status') != 'C' && order.get('status') != 'F'"
                @click="myFunction3(order)"
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                class="see-btn"
              >
                Cancelar
              </button>
              <button
                v-if="
                  order.get('status') === 'A' || order.get('status') === 'IP'
                "
                @click="myFunction2(order)"
                type="button"
                style="background-color:green !important;"
                class="delete-btn"
              >
                Finalizar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        v-if="allOrders === null || allOrders === ''"
        style="font-size:14px; text-align:center;"
      >
        No hay ordenes disponibles.
      </p>
    </div>
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content add-product-form">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-family: 'Montserrat', sans-serif;"
            >
              Información de Orden
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- <div class="modal-body">
                        <h1 class="modal-title-address">Dirección</h1>
                        <p class="address-info"><b>Address:</b> {{address}}</p>
                        <p class="address-info"><b>Ciudad:</b> {{city}}</p>
                        <p class="address-info"><b>Zipcode:</b> {{zipcode}}</p>
                    </div> -->
          <div class="modal-body">
            <h1 class="modal-title-address">Información de Pago</h1>
            <p class="address-info">
              <b>Nombre de la Tienda:</b> {{ nameOfStore }}
            </p>
            <p class="address-info"><b>IVU:</b> ${{ ivu }}</p>
            <p class="address-info">
              <b>Costo de Delivery:</b> ${{ deliveryFee }}
            </p>
            <p class="address-info"><b>Ganancia de Delivery:</b> $0</p>

            <p class="address-info">
              <b>Ganancia de Tienda + IVU:</b> ${{ storeGainIvu }}
            </p>
            <p class="address-info"><b>Total de Compra:</b> ${{ total }}</p>
          </div>
          <div class="modal-body" style="height: 300px; overflow: scroll;">
            <div class="row justify-content-center">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="col col-5 mt-3"
                style="margin-bottom: 20px;"
              >
                <img
                  v-bind:class="{
                    suprimirImg: item.estado == false,
                    'center-img': item.estado == true
                  }"
                  class="center-img"
                  :src="item.prodImage"
                  width="100px"
                  height="100px"
                />
                <!-- suprimido
                suprimirImg -->
                <p
                  v-bind:class="{
                    suprimido: item.estado == false,
                    'address-info2': item.estado == true
                  }"
                >
                  {{ item.prodName }}
                </p>
                <p
                  v-bind:class="{
                    suprimido: item.estado == false,
                    'address-info2': item.estado == true
                  }"
                >
                  ${{ item.prodPrice }}
                </p>
                <p
                  v-bind:class="{
                    suprimido: item.estado == false,
                    'address-info2': item.estado == true
                  }"
                >
                  {{
                    item.quantityNumber > 1
                      ? item.quantityNumber + " articulos"
                      : item.quantityNumber + " articulo"
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #FFC93A; border: 0;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary" style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #0e9390; border: 0;">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarAdmin from "../../components/NavBarAdmin.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Parse from "parse";
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};
Vue.use(VueSweetalert2, options);
export default {
  name: "OrdersAdmin",
  components: { NavBarAdmin },
  data() {
    return {
      allOrders: null,
      address: null,
      city: null,
      zipcode: null,
      card: null,
      date: null,
      cardName: null,
      referenceNumber: null,
      total: null,
      items: null,
      totalOrders: null,
      nameOfStore: null,
      cost: null,
      ivu: null,
      deliveryFee: null,
      buypinGain: null,
      storeGain: null,
      driverGain: null,
      audio: null,
      storeGainIvu: null,
      buyPinPlusCommision: null,
      deliveryCommision: null,
      superAdmin: false,
      admin: false,
      orderId: ""
    };
  },
  watch: {
    orderId() {
      if (this.orderId !== "") {
        this.buscarOrderId();
      } else {
        this.getAllOrders();
      }
    }
  },
  mounted() {
    this.superAdmin = Parse.User.current().get("superAdmin");
    this.admin = Parse.User.current().get("isAdmin");
    if (this.admin && !this.superAdmin) {
      this.$router.push("/userTabs");
    }

    if (!this.admin && !this.superAdmin) {
      this.$router.push("/");
    }
    // this.audio = new Audio(
    //   "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3"
    // );
    let client = new Parse.LiveQueryClient({
      applicationId: "C0XMchZu6Y9XWNUK4lM1UHnnuXhC3dcdpa5fGYpO",
      serverURL: "wss://" + "coopharma.back4app.io", // Example: 'wss://livequerytutorial.back4app.io'
      javascriptKey: "EdN4Xnln11to6pfyNaQ5HD05laenoYu04txYAcfo",
      masterKey: "4ZCJxnFPx9nTK4SWDwBq4imO8MOj8e01L9KoDyyO"
    });
    client.open();
    let query = new Parse.Query("ServiceRequest");
    query.descending("createdAt");
    let subscription = client.subscribe(query);
    subscription.on("create", () => {
      this.getAllOrders();
    });
    subscription.on("update", () => {
      this.getAllOrders();
    });
    subscription.on("delete", () => {
      this.getAllOrders();
    });
    if (Parse.User.current() === null) {
      this.$router.push("/HelloWorld");
    }
    this.getAllOrders();
  },
  methods: {
    buscarOrderId() {
      let query = new Parse.Query("ServiceRequest");
      // let storeId = "";
      query.contains("objectId", this.orderId);
      query.descending("createdAt");
      query.find().then(results => {
        this.totalOrders = results;
      });
    },
    getAllOrders() {
      this.totalOrders = [];
      Parse.Cloud.run("getAllOrders", {
        // get the user store
      }).then(result => {
        this.allOrders = result;
        for (let i = 0; i < this.allOrders.length; i++) {
          this.totalOrders.push(this.allOrders[i]);
        }
      });
    },
    verDriver(data) {
      this.$router.push("/driversProfile/" + data.id);
    },
    status(data) {
      if (data === "F") {
        return "Finalizado";
      }
      if (data === "A") {
        return "Aprobado";
      }
      if (data === "C") {
        return "Cancelado";
      }
      if (data === "FR") {
        return "Reseñado";
      }
      if (data === "IP") {
        return "En Proceso";
      } else {
        return "Solicitado";
      }
    },

    viewInfo(data) {
      this.nameOfStore = data.get("store").get("Name");
      this.ivu = parseFloat(data.get("taxes").toFixed(2));
      this.deliveryFee = parseFloat(data.get("deliveryFee")).toFixed(2);
      this.driverGain = parseFloat(data.get("totalDriverGain")).toFixed(2);
      this.buypinGain = data.get("buypinGain").toFixed(2);
      this.storeGain = data.get("storeGain").toFixed(2);
      // let totalStoreAndIVU =
      //   parseFloat(data.get("buypinGain")) + data.get("storeGain");
      let buyPinComission = data.get("buypinGain") + data.get("comisionBuyPin");
      this.buyPinPlusCommision = buyPinComission.toFixed(2);
      this.storeGainIvu = data.get("storeGain").toFixed(2);
      this.deliveryCommision = parseFloat(data.get("comisionBuyPin")).toFixed(
        2
      );
      this.card = data.get("user").get("stripeCustomer");
      this.cardName = data.get("user").get("fullName");
      this.date = data.get("date");
      this.total = data.get("orderTotal");
      this.items = data.get("items");
    },
    myFunction(order) {
      let txt;
      if (confirm("¿Estás seguro que quieres borrar esta Orden?")) {
        txt = "You pressed OK!";
        this.deleteOrder(order);
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    },
    deleteOrder(data) {
      data.destroy().then(() => {
        this.getAllOrders();
      });
    },
    myFunction2(order) {
      let txt;
      if (confirm("¿Estás seguro que quieres finalizar esta orden?")) {
        txt = "You pressed OK!";
        this.finalOrder(order);
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    },
    async finalOrder(order) {
      await Parse.Cloud.run("makeStripePayment", { serviceId: order.id }).then(
        () => {
          this.getAllOrders();
        }
      );
    },
    myFunction3(order) {
      let txt;
      if (confirm("¿Estás seguro que quieres cancelar esta orden?")) {
        txt = "You pressed OK!";
        this.cancelar(order);
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    },
    async cancelar(order) {
      await Parse.Cloud.run("cancel", { serviceId: order.id }).then(() => {
        this.getAllOrders();
      });
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}
.nav-item {
  font-family: "Montserrat", sans-serif;
}
.nav-link {
  color: black !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}
.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}
.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}
.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}
.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}
.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}
.icon3 {
  color: #029693;
  margin-right: 10px;
}
.table {
  font-family: "Montserrat", sans-serif;
}
.font {
  font-weight: 600 !important;
  text-align: center;
  font-size: 13px;
}
.font2 {
  font-weight: 300;
  font-size: 13px;
  text-align: center;
  padding-top: 25px;
}
.center-number {
  text-align: center;
  font-size: 13px;
  padding-top: 25px;
}
.table-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.table-custom {
  background: #f8f9fa !important;
  color: black !important;
}
.table-content {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
}
.delete-btn {
  background: #be982f;
  height: 35px;
  border: none;
  color: white;
  font-weight: 400;
  width: 100px;
}
.see-btn {
  background: rgba(212, 42, 42, 0.836);
  height: 35px;
  border: none;
  color: white;
  font-weight: 400;
  width: 100px;
}
.modal-title-address {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}
.address-info {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: rgb(143, 143, 143);
  margin: 2px;
}
.address-info2 {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: rgb(143, 143, 143);
  margin: 2px;
  text-align: center;
}
.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.suprimirImg {
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  margin: -10px;
  filter: opacity(20%);
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.suprimido {
  color: rgb(175, 173, 173);
  font-size: 12px;
  text-decoration: line-through;
  margin-top: 10px;
  margin-left: 4%;
  text-align: center;
}
</style>
